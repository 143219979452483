<!-- studentInfo -->
<template>
  <div class="content has_breadcrumb">
    <!--  section-info1 -->
    <section-info1
      :data="formDataList"
      :renderPass="renderPass"
      title="Location profile"
      :iconEdit="false"
      :isShowLabel="true"
      :showPlaceholder="false"
      :inline="true"
      submitBtnTxt="Save"
      :readonly.sync="readonly"
      @submit="submitEdit"
      @cancel="handleCancel"
      @input="input"
      v-loading="loading"
    >
      <template slot="renderHtml" slot-scope="scope">
        <div v-if="scope.data.key === 'image'" class="image">
          <div
            class="upload-img"
            @click="uploadImage"
            :style="{
              backgroundColor: scope.data.value ? 'transparent' : '#eee',
            }"
          >
            <img
              v-if="scope.data.value"
              :src="scope.data.value"
              alt="上传图片"
              srcset=""
              width="100%"
              class="img"
            />
            <i v-else class="material-icons iconfont iconadd_a_photo" />
          </div>
          <div class="attachment" @click="uploadImage">Add image</div>
        </div>

        <gg-input
          v-else-if="scope.data.key === 'name'"
          :key="scope.data.key"
          :ref="scope.data.key"
         
          v-model="scope.data.value"
          :error="nameErrorObj"
          inline
        />

        <baseGGSelect
          v-else-if="scope.data.key === 'country'"
          v-model="scope.data.value"
          
          :key="scope.data.key"
          :ref="scope.data.key"
          inline
        >
          <md-option
            v-for="item in countryList"
            :key="item.id"
            :value="item.value"
          >
            {{ item.value }}
          </md-option>
        </baseGGSelect>

        <gg-input
          v-else-if="scope.data.key === 'email'"
          :key="scope.data.key"
          :ref="scope.data.key"
  
          v-model="scope.data.value"
          :error="emailErrorObj"
          inline
        />

        <div v-else-if="scope.data.key == 'tel'" class="not-readonly-content">
          <PhoneNumber
            placeholder=""
            v-model="scope.data.value"
            :ref="scope.data.key"
            :float="true"
          ></PhoneNumber>
        </div>
        
      </template>
      <p slot="tip" >*indicates a required field</p>
    </section-info1>
    
    <imgCutModal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      @_uploadCallback="_uploadCallback"
	  :fixed-number="[16,10]"
    />
    <Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      formDataList: [
        {
          placeholder: "Name *",
          key: "name",
          value: "",
          renderHtml: true,
          reg: /^[\S\s]{1,80}$/,
          errorText: "Character limit of 80",
        },
        {
          placeholder: "Image",
          key: "image",
          value: "",
          renderHtml: true,
        },
        {
          placeholder: "Address *",
          key: "address",
          value: "",
          reg: /[\S]/,
          errorText: "Address is required.",
        },
        {
          placeholder: "Address line 2",
          key: "addressLine2",
          value: "",
          // reg: /[\s\S]/,
          // errorText: "",
        },
        {
          placeholder: "Postal code *",
          key: "postalCode",
          value: "",
          reg: /[\S]/,
          errorText: "Postal code is required",
        },
        {
          placeholder: "Country *",
          key: "country",
          value: "",
          roleList: [
            { value: "Singepore", label: "Singepore" },
            { value: "China", label: "China" },
            { value: "English", label: "English" },
          ],
          renderHtml: true,
        },
        {
          placeholder: "Email",
          key: "email",
          value: "",
          renderHtml: true,
          // reg: /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/,
          // errorText: "Email address is invalid",
        },
        {
          placeholder: "Phone",
          key: "tel",
          value: {
            countryCode: "+65",
            phoneNumber: "",
          },
          renderHtml: true,
        },
      ],
      renderPass: true,
      slotData: ["country"], //"image", "country", "phoneNo"
      isDisabled: true,
      readonly: false,
      isImgCutShow: false,
      locationId: this.$route.query.id,
      countryList: [],
      snackbar: {
        isShow: false,
        content: "",
      },
      loading: false,
	  is_repeated:false
    };
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user,
    }),
    nameErrorObj() {
      let name = this.formDataList.find((item) => item.key === "name").value;
		if(this.is_repeated){
			return {
			  show: true,
			  message: "Name already exists",
			};
		}
      if (name === "") {
        return {
          show: true,
          message: "Name is required",
        };
      }

      let isPass = name.length < 80;
      return {
        show: !isPass,
        message: "Character limit of 80",
      };
    },
    emailErrorObj() {
      let email = this.formDataList.find((item) => item.key === "email").value;
      if (email === "" || email == null) {
        return {
          show: false,
          message: "",
        };
      }
      let isPass =/^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/.test(email);
      return {
        show: !isPass,
        message: "Email address is invalid",
      };
    },
  },
  watch: {
    formDataList: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          let aaa = this.slotData.every(
            (item) => this.$refs[item] && !!this.$refs[item].value
          );

          this.renderPass =
            aaa && !this.$refs.tel.isError.show;
          console.log("renderPass:", this.renderPass);
        });
      },
    },
  },
  methods: {
    _getData() {
      let data = {
        id: this.locationId,
      };
      this.loading = true;
      Ajax.get("/usermanage/location/selectLocationById", data)
        .then((res) => {
          let response = res.data;
          this.loading = false;
          this.formDataList.forEach((item) => {
            item.value = response[item.key];
            if (item.key == "tel") {
              item.value = {
                countryCode: response.countryCode ? response.countryCode : "",
                phoneNumber: response.phoneNumber ? response.phoneNumber : "",
              };
            }
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _getCountry() {
      var data = {
        userId: this.user.userId,
        dataLevel: "general",
        dataType: "country",
      };
      this.loading = true;
      Ajax.post("/usermanage/setting/getList", data)
        .then((res) => {
          let response = res.data;
          this.loading = false;
          this.countryList = response || [];
        })
        .catch(() => {
          this.loading = false;
        });
    },
    input(res) {
      console.log(res);
    },
    handleCheck(val) {
      this.checkedData = val;
      // console.log(this.locations);
    },
    handleCancel() {
      // this.readonly = false;
      this.$router.back();
    },
    submitEdit() {
      this.readonly = false;

      let formData = {
        userId: this.user.userId,
        id: this.locationId,
        name: "",
        image: "",
        address: "",
        addressLine2: "",
        postalCode: "",
        country: "",
        email: "",
        countryCode: "",
        phoneNumber: "",
      };

      this.formDataList.forEach((item) => {
        if (item.key == "tel") {
          formData["countryCode"] = item.value.countryCode;
          formData["phoneNumber"] = item.value.phoneNumber;
        } else {
          formData[item.key] = item.value;
        }
      });
      if (this.locationId) {
        this.updateLocation(formData);
      } else {
        this.saveLocation(formData);
      }
    },
    saveLocation(formData) {
      Ajax.post("/usermanage/location/saveLocationStaff", formData).then(
        (res) => {
          let response = res.data;
          this.handleSuspend(res.message);
          this.$router.go(-1);
        }
      );
    },
    updateLocation(formData) {
      Ajax.post("/usermanage/location/updateLocationStaff", formData).then(
        (res) => {
			if(res.code== '0000'){
				let response = res.data;
				this.handleSuspend(res.message);
				this.$router.go(-1);
			}else if(res.message==="Location exists"){
				this.is_repeated=true
				this.nameErrorObj
				
				// console.log(
				// this.handleSuspend(res.message);
			}
         
        }
      );
    },
    uploadImage() {
      this.isImgCutShow = true;
    },
    _uploadCallback(formData, fileData) {
      formData.append("multipartFile", fileData.raw);
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          this.formDataList.forEach((item) => {
            if (item.key == "image") {
              item.value = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
            }
          });
          this.isImgCutShow = false;
        })
        .catch(() => {
          this.isImgCutShow = false;
        });
    },
    handleSuspend(content) {
      this.snackbar.isShow = true;
      this.snackbar.content = content;
    },
  },
  created() {
    if (this.locationId && this.locationId !== "") {
      this._getData();
    }
    this._getCountry();
  },
};
</script>
<style lang='less' scoped>
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  /deep/.float {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    flex-grow: 0;
    flex-shrink: 0;
  }
  .image {
    width: calc(100% - 140px);

    .upload-img {
		margin: 0;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 320px;
      height: 132px;
      /* line-height: 100px; */
      text-align: center;
      background: #eee;
      color: #fff;
      border-radius: 0%;
      cursor: pointer;
	  /* margin: ; */
    }
    .img {
      width: 160px;
      height: auto;
      object-fit: cover;
    }
    .iconadd_a_photo {
    }
    .attachment {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-family: "OpenSans-bold";
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      width: 116px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        left: 10px;
        height: 15px;
        width: 7px;
        background: url("~@/assets/images/settings/attachment.png") no-repeat;
        background-size: contain;
      }
    }
  }
}
.attachment {
}
.not-readonly-content {
  flex-basis: calc(100% - 140px);
  width: calc(100% - 140px);
  padding: 30px 0 0 !important;
  .phone-number-content {
    width: 100%;
  }
}
.content .image .upload-img{
	justify-content:left!important;
	
}
.content .image .upload-img img{
	height: 132px;
	width: 320px;
}

.upload-img .iconfont{
	margin: auto;
}
/deep/.section-info-main .not-readonly-content{
	max-width: 900px;
}
</style>